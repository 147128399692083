import * as React from 'react'
import { Component } from 'react'
import { RouteComponentProps, Link } from "react-router-dom";
import { withRouter, InvitationType, GLGlobal, GLLocale, GLUtil } from 'gl-commonui';
import { PathConfig } from '@app/config/pathconfig';
import { InvitationsComPage } from '@app/components';
import { SchoolLocale } from '@app/locales/localeid';
import { Select, Modal } from 'antd-min';
import { GSSchoolAction } from '@app/util/enum';
import { SchoolTeacherModel } from '@app/service/schools';

@withRouter
export class SchoolTeachersPage extends Component<RouteComponentProps<any>, any> {
    clickedBtns = []
    constructor(props, context) {
        super(props);
        this.state = {}

    }
    renderOption(item, index) {
        return <Select.Option key={index} value={index} title={item}>{item}</Select.Option>
    }
    render() {
        const { match: { params: { regionId, schoolId } } } = this.props;
        const fmtMsg = GLGlobal.intl.formatMessage;
        return (
            <InvitationsComPage 
                refId={schoolId} 
                typeId={InvitationType.schoolTeacher} 
                typeTitle={fmtMsg({ id: SchoolLocale.SchoolTeacherTitle })}
                intl={getIntl()} 
                clickedBtns={this.clickedBtns}
                addInvitationAction={GSSchoolAction.AddSchoolTeacher}
                deleteInvitationAction={GSSchoolAction.DeleteSchoolTeacher}
                sendEmailAction={GSSchoolAction.ResendSchoolTeacherEmail}
                removeHandler={removeHandler}
            />
        )
    }
}
function getColumns(regionId, schoolId) {
    const fmtMsg = GLGlobal.intl.formatMessage;
    return [{
        title: fmtMsg({ id: SchoolLocale.SchoolTeacherName }),
        dataIndex: "name",
        width: "30%",
        render: (text, record, index) => {
            const teacherName = record.pending ? `${(record.name || record.id)} (${GLGlobal.intl.formatMessage({ id: SchoolLocale.TeacherPending })})` : record.name;
            const pathSchoolTeacher = regionId && schoolId ? GLUtil.pathStringify(PathConfig.SchoolTeacher, { regionId, schoolId, teacherId: record.id }) : null;
            return pathSchoolTeacher && GLGlobal.isActionValid(GSSchoolAction.SchoolTeacherHome) && teacherName && !record.pending ?
                <Link className={'navigation-link'} to={pathSchoolTeacher}>
                    {teacherName}
                </Link>
                : teacherName             
        }
    }, {
        title: fmtMsg({ id: SchoolLocale.SchoolTeacherClass }),
        dataIndex: "class",
        width: "30%"
    }, {
        title: fmtMsg({ id: SchoolLocale.SchoolTeacherEmail }),
        dataIndex: "email",
        width: "30%"
    }]
}
function getIntl() {
    return {
        Invite: SchoolLocale.InvitationInviteTeacher,
        InviteTitle: SchoolLocale.SchoolTeacherInviteModalTitle,
    }
}
function removeHandler(record: SchoolTeacherModel, remove, removePending) {
    const fmtMsg = GLGlobal.intl.formatMessage;
    const classes = record.isInClass ? record.teachClass.map(c=> c.name).join(', ') : '';
    record.isInClass && Modal.confirm({
        title: fmtMsg({ id: SchoolLocale.SchoolTeacherDeleteModalTitle }),
        content: fmtMsg({ id: SchoolLocale.SchoolClassTeacherDeleteForceMessage }, {count: record.teachClass.length}),
        okText: fmtMsg({ id: GLLocale.Ok }),
        cancelText: fmtMsg({ id: GLLocale.Cancel }),
        onOk() { remove({...record, forceDelete: true}) }, // Called when teacher have active class
    });
    !record.teachClass && Modal.confirm({
        title: fmtMsg({ id: SchoolLocale.SchoolTeacherRevokeModalTitle }),
        content: fmtMsg({ id: SchoolLocale.SchoolTeacherPendingDeleteMessage }),
        okText: fmtMsg({ id: GLLocale.Ok }),
        cancelText: fmtMsg({ id: GLLocale.Cancel }),
        onOk: () => { removePending(record) }
    });
    record.teachClass && record.teachClass.length == 0 && Modal.confirm({
        title: fmtMsg({ id: SchoolLocale.SchoolTeacherDeleteModalTitle }),
        content: fmtMsg({ id: SchoolLocale.SchoolTeacherDeleteMessage }, {name: record.name}),
        okText: fmtMsg({ id: GLLocale.Ok }),
        cancelText: fmtMsg({ id: GLLocale.Cancel }),
        onOk: () => { remove({...record, forceDelete: true}) } // called when teacher don't have any class
    });    
    return !record.isInClass;
}