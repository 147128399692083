import * as React from "react";
import { Component } from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import {
    alignPop,
    FormHelper,
    GLForm,
    GLFormComponentProps,
    GLGlobal,
    InvitationType,
    MessageHelper,
    NotificationType,
    PaginationParams,
    RoleName,
    withRouter,
} from "gl-commonui";
import { SchoolLocale } from "../../../locales/localeid";
import { Container, ListStatesStore, MainTitle } from "../../../components";
import { StateType } from "@app/states";
import Table, { TableProps } from "antd/lib/table";
import { InvitationInfo, InvitationSelectModel, InvitationStatus } from "../../../service/school/invitation";
import { Button, Input, Radio, Select } from "antd-min";
import { fmtMsg, onSubmit } from "../../../util/func";
import { getClassesSimple } from "../../../states/school/classes";
import { InvitationChannel, InvitationListStates } from "../../../util/enum";
import { getInvitationCodeDetails } from "../../../states/school/invitation";
import { DateHelper } from "../../../util/helper";
import { CellType, GLGrid, GLGridSorter } from "@app/components/gl-grid";
import { isNull, isUndefined } from "util";
import { PortalInvitation } from "@app/components/school/portal-invitation";
import { AllowResizing, HeadersVisibility, SelectionMode } from "wijmo/wijmo.grid";
import "./invitation.less";
import moment from "moment";
import { formatName, isPhoneRegistrationEnable, lazyInject, phoneValidation, TYPES } from "@app/util";
import { IInvitationService } from "@app/service/admin/acceptinvitation";
import { IRegionService } from "@app/service/admin/regions";
import { setFutureAnnualOuter } from "@app/states/school/class";

interface InvitationsPageProps {
    list: any[];
    cnts: any;
    loading: boolean;
    dataSource: any[];
    getInvitationCodeDetails: (id) => void;
    getClassesSimple: (query) => void;
    setFutureAnnualOuter: (d) => void;
}

enum InvitationBannerType {
    Print = 1,
    Email = 2,
    Phone = 3
}

enum FilterType {
    Type = "invitationCodeType",
    Code = "code",
    Email = "email",
    Phone = "phone",
    Class = "schoolClassId",
    CreatedDate = "daysOffset",
}
interface TypeShowModel {
    showTeacherType: boolean;
    showCampusAdminType: boolean;
    showSchoolAdminType: boolean;
}

class InvitationsTable extends Table<InvitationInfo> { }

const getSelectionTypeVisibility = () => {
    let assignedRoles = (GLGlobal.loginInfo().profile || { roles: [] }).roles;
    let parentIndex = assignedRoles.indexOf(RoleName.parent);
    let requiredRoles = [];
    let otherRoles = [];
    let returnData: TypeShowModel = { showTeacherType: true, showCampusAdminType: true, showSchoolAdminType: true };

    if (parentIndex > -1) {
        assignedRoles.splice(parentIndex, 1);
    }

    assignedRoles.forEach((r) => {
        if (r === RoleName.teacher || r === RoleName.campusAdmin || r === RoleName.schoolAdmin) {
            requiredRoles.push(r);
        } else {
            otherRoles.push(r);
        }
    });

    if (otherRoles.length > 0) {
        return returnData;
    } else {
        if (requiredRoles.includes(RoleName.schoolAdmin)) {
            returnData.showTeacherType = true;
            returnData.showCampusAdminType = true;
            returnData.showSchoolAdminType = false;
        } else if (requiredRoles.includes(RoleName.campusAdmin)) {
            returnData.showTeacherType = true;
            returnData.showCampusAdminType = false;
            returnData.showSchoolAdminType = false;
        } else {
            returnData.showTeacherType = false;
            returnData.showCampusAdminType = false;
            returnData.showSchoolAdminType = false;
        }

        return returnData;
    }
};

@withRouter
@GLForm.create()
@connect(
    ({ invitation: { invitations, cnts, loading }, classes: { dataSource } }: StateType) => ({
        list: invitations,
        cnts,
        loading,
        dataSource,
    }),
    { getInvitationCodeDetails, getClassesSimple, setFutureAnnualOuter }
)
export class InvitationsPage extends Component<RouteComponentProps<any> & InvitationsPageProps & GLFormComponentProps,
    { listState; filterType; selectedItems; invitationBanner: InvitationBannerType[]; showInvitationModal; enablePhoneRegistration: boolean; }
> {
    pagination: PaginationParams = new PaginationParams(1, 20);
    tableProps: TableProps<InvitationInfo> = {
        rowKey: "code",
        pagination: this.pagination,
        onChange: this.handleTablePageChange.bind(this),
    };
    listStateStore: ListStatesStore;
    query: any = { filters: { [FilterType.Type]: InvitationType.parent }, sorters: {} };
    flexContext: any;
    invitationModalProps: any = {
        heading: "",
        invitationType: InvitationType.parent,
        isEmail: false,
        isPhone: false,
        selectedItems: [],
    };
    isPrintVisible: boolean = true;
    isToShowTeacherType: boolean = true;
    isToShowCampusAdminType: boolean = true;
    isToShowSchoolAdminType: boolean = true;
    greenClass = "invitations-wijmo-green-svg";
    redClass = "invitations-wijmo-red-svg";

    @lazyInject(TYPES.IInvitationService)
    invitationService: IInvitationService

    @lazyInject(TYPES.IRegionService)
    regionService: IRegionService

    constructor(props, context) {
        super(props, context);
        let typeVisibility = getSelectionTypeVisibility();
        let filterTypeToShow = FilterType.Type;
        let currentStoreId = this.props.match.params.schoolId;
        let defaultInvitationState = InvitationListStates.All;

        this.isToShowTeacherType = typeVisibility.showTeacherType;
        this.isToShowCampusAdminType = typeVisibility.showCampusAdminType;
        this.isToShowSchoolAdminType = typeVisibility.showSchoolAdminType;

        if (this.isFromClass()) {
            this.query = { filters: { [FilterType.Class]: this.props.match.params.classId }, sorters: {} };
            filterTypeToShow = FilterType.Class;
            currentStoreId = this.props.match.params.classId;
            defaultInvitationState = InvitationListStates.Pending;
        }

        this.listStateStore = new ListStatesStore("invitations-list-states", currentStoreId);

        this.state = {
            filterType: filterTypeToShow,
            listState: this.listStateStore.getState(currentStoreId, defaultInvitationState),
            selectedItems: [],
            invitationBanner: [],
            showInvitationModal: false,
            enablePhoneRegistration: false
        };
    }
    componentDidMount() {
        let filterTypeToShow = FilterType.Type;

        if (this.isFromClass()) {
            filterTypeToShow = FilterType.Class;
        }

        const {
            match: { params }
        } = this.props;
        //It will obtain the class information that has been used in the breadcrumb.
        this.props.setFutureAnnualOuter(params);

        this.getEnbalePhoneRegistrationSetting();
        this.props.form.setFieldsValue({ filterType: filterTypeToShow });
        this.initFilterValue(this.props.form, filterTypeToShow);
        this.props.getClassesSimple({
            query: { schoolId: this.props.match.params.schoolId, isDetail: false, disabled: false },
        });
        this.getInvitations();
        window.addEventListener('click', this.onClickOutsideHandler);
    }

    getEnbalePhoneRegistrationSetting() {
        if (this.props.match.params && this.props.match.params.regionId) {
            this.regionService.getRegionPhoneRegistrationSetting(this.props.match.params.regionId)
                .then(data => {
                    this.setState({ enablePhoneRegistration: data });
                }).catch(resp => { });
        }
    }

    // event when user click any where outside the kebob menu icon
    onClickOutsideHandler = (e) => {
        if (!this.isKebobMenuIcon(e)) {
            this.hideAllKebobMenus();
        }
    }

    isKebobMenuIcon = (e): boolean => {
        return e && e.target && e.target.getAttribute("data-icon") == "grid-kebob";
    }

    componentWillUnmount() {
        if (this.isFromClass()) {
            this.listStateStore.setState(InvitationListStates.Pending);
        } else {
            this.listStateStore.setState(InvitationListStates.All);
        }
        window.removeEventListener('click', this.onClickOutsideHandler);
    }
    onInitializeGrid(grid) {
        grid.parent = this;
        grid.isUpdateRequired = false;
        grid.isFromRowHeader = false;
        grid.isChecked = false;
        this.flexContext = grid;

        //adding checkboxes
        grid.formatItem.addHandler((s, e) => {
            if (e.panel.cellType == CellType.RowHeader || e.panel.cellType == CellType.TopLeft) {
                e.cell.innerHTML = `<input class="invitations-wijmo-checkboxes" type="checkbox">`;
            }

            disableCheckBox(grid);
        });

        const syncComponentStateToGrid = () => {
            let selectedData = updateCheckBox(grid);
            if (grid.parent && grid.isUpdateRequired) {
                grid.parent.setViewAfterSelection(selectedData, grid.isFromRowHeader, grid.isChecked);
                grid.isFromRowHeader = false;
                grid.isChecked = false;
                grid.isUpdateRequired = false;
            }
        }

        const calcDisabledRows = () => {
            let vr = grid.viewRange;
            let disabledCount = 0;
            for (let i = vr.topRow; i <= vr.bottomRow; i++) {
                let cb = grid.rowHeaders.getCellElement(i, 0);
                if (
                    !!cb &&
                    grid.rows[i]._data !== undefined &&
                    grid.rows[i]._data.invitationStatus !== InvitationListStates.Pending
                ) {
                    disabledCount = disabledCount + 1;
                }
            }
            return disabledCount;
        }

        // select grid on checkbox click
        grid.hostElement.addEventListener("click", function (e) {
            let ht = grid.hitTest(e);
            let headerCb = grid.topLeftCells.getCellElement(0, 0).firstChild;
            if (e.target.type == "checkbox" && ht.cellType == CellType.RowHeader) {
                if (
                    grid.rows[ht.row]._data !== undefined &&
                    grid.rows[ht.row]._data.invitationStatus === InvitationListStates.Pending
                ) {
                    grid.isUpdateRequired = true;
                    grid.isFromRowHeader = true;
                    grid.isChecked = e.target.checked;
                    let checkedRows = getCheckedRows();
                    const disabledRows = calcDisabledRows();
                    let rowCount = grid.rows.length,
                        checked = checkedRows > 0,
                        indeterminate = checkedRows > 0 && rowCount - disabledRows > checkedRows;
                    headerCb.checked = checked;
                    headerCb.indeterminate = indeterminate;
                }

                syncComponentStateToGrid()
            }
            if (e.target.type == "checkbox" && ht.cellType == CellType.TopLeft) {
                let rowsLen = grid.rows.length;
                let pendingRowsLen = getPendingRowsCount();
                let pendingTracked = 0;
                for (let i = 0; i < rowsLen; i++) {
                    if (
                        grid.rows[i]._data !== undefined &&
                        grid.rows[i]._data.invitationStatus === InvitationListStates.Pending
                    ) {
                        pendingTracked = pendingTracked + 1;
                        if (pendingRowsLen === pendingTracked) {
                            grid.isUpdateRequired = true;
                        }
                        grid.rowHeaders.getCellElement(i, 0).firstChild.checked = e.target.checked;
                    }
                }
                syncComponentStateToGrid()
            }

            if (e.target && e.target.getAttribute("data-isPrint")) {
                if (grid.parent) {
                    let dataToSend = [
                        {
                            id: e.target.getAttribute("data-id"),
                            referenceId: e.target.getAttribute("data-referenceId"),
                            type: parseInt(e.target.getAttribute("data-type")),
                        },
                    ];
                    grid.parent.printInvitations(dataToSend);
                }
            }
            if (e.target && e.target.getAttribute("data-isEmail")) {
                if (grid.parent) {
                    let dataToSend = [
                        {
                            id: e.target.getAttribute("data-id"),
                            referenceId: e.target.getAttribute("data-referenceId"),
                            type: parseInt(e.target.getAttribute("data-type")),
                            email: e.target.getAttribute("data-email"),
                        },
                    ];
                    grid.parent.emailInvitations(dataToSend);
                }
            }
            if (e.target && e.target.getAttribute("data-isPhone")) {
                if (grid.parent) {
                    let dataToSend = [
                        {
                            id: e.target.getAttribute("data-id"),
                            referenceId: e.target.getAttribute("data-referenceId"),
                            type: parseInt(e.target.getAttribute("data-type")),
                            phone: e.target.getAttribute("data-phone"),
                        },
                    ];
                    grid.parent.smsInvitations(dataToSend);
                }
            }
            if (e.target && e.target.getAttribute("data-renew")) {
                grid.parent.invitationService.extendExpirationDate(e.target.getAttribute("data-invitation-code")).then((res) => {
                    if (res) {
                        grid.parent.getInvitations();
                    }
                }).catch((e) => {

                });
            }
            if (grid.parent.isKebobMenuIcon(e)) {
                const code = e.target.getAttribute("data-code");
                if (code) {
                    grid.parent.hideAllKebobMenus();
                    document.getElementById("grid-kebob-" + code).style.display = "block";
                }
            }
        });
        grid.updatedView.addHandler(function (grid, e) {
            const bindComponentStateToCheckbox = () => {
                let vr = grid.viewRange;
                for (let i = vr.topRow; i <= vr.bottomRow; i++) {
                    let cb = grid.rowHeaders.getCellElement(i, 0);
                    if (!!cb) {
                        if (!cb.firstChild.disabled) {
                            cb.firstChild.checked = !!grid.parent.state.selectedItems.find(item => item.id === grid.rows[i]._data.id)
                        }
                    }
                }
                let headerCb = grid.topLeftCells.getCellElement(0, 0).firstChild;
                let checkedRows = getCheckedRows();
                const disabledRows = calcDisabledRows();
                let rowCount = grid.rows.length,
                    checked = checkedRows > 0,
                    indeterminate = checkedRows > 0 && rowCount - disabledRows > checkedRows;
                headerCb.checked = checked;
                headerCb.indeterminate = indeterminate;
            }
            bindComponentStateToCheckbox()
        });
        function updateCheckBox(grid) {
            let vr = grid.viewRange;
            let selectedData = [];
            for (let i = vr.topRow; i <= vr.bottomRow; i++) {
                let cb = grid.rowHeaders.getCellElement(i, 0);
                if (!!cb) {
                    if (!cb.firstChild.disabled) {
                        if (cb.firstChild.checked && grid.rows[i]._data !== undefined) {
                            let rowData = grid.rows[i]._data;
                            selectedData.push({
                                id: rowData.id,
                                code: rowData.code,
                                email: rowData.email,
                                phone: rowData.phone,
                                invitationStatus: rowData.invitationStatus,
                                type: rowData.type,
                                isEmailed: rowData.isEmailed,
                                isPrinted: rowData.isPrinted,
                                isSMSSent: rowData.isSSMSSent,
                                referenceId: rowData.referenceId,
                            });
                        }
                    }
                }
            }
            return selectedData;
        }
        function disableCheckBox(grid) {
            let vr = grid.viewRange;
            let disabledCount = 0;
            for (let i = vr.topRow; i <= vr.bottomRow; i++) {
                let cb = grid.rowHeaders.getCellElement(i, 0);
                if (
                    !!cb &&
                    grid.rows[i]._data !== undefined &&
                    grid.rows[i]._data.invitationStatus !== InvitationListStates.Pending
                ) {
                    cb.firstChild.disabled = true;
                    disabledCount = disabledCount + 1;
                }
            }

            if (
                grid.topLeftCells.getCellElement(0, 0) !== null &&
                grid.topLeftCells.getCellElement(0, 0).firstChild !== undefined
            ) {
                let headerCb = grid.topLeftCells.getCellElement(0, 0).firstChild;
                if (disabledCount == grid.rows.length) {
                    headerCb.disabled = true;
                }
            }
        }
        //get selected rowCount
        function getSelectedRows() {
            let cnt = 0;
            for (let i = 0; i < grid.rows.length; i++) {
                if (grid.rows[i].isSelected) {
                    cnt++;
                }
            }
            return cnt;
        }

        function getCheckedRows() {
            let cnt = 0;
            for (let i = 0; i < grid.rows.length; i++) {
                if (grid.rowHeaders.getCellElement(i, 0) && grid.rowHeaders.getCellElement(i, 0).firstChild.checked) {
                    cnt++;
                }
            }
            return cnt;
        }

        function getPendingRowsCount() {
            let cnt = 0;
            for (let i = 0; i < grid.rows.length; i++) {
                if (
                    grid.rows[i]._data !== undefined &&
                    grid.rows[i]._data.invitationStatus === InvitationListStates.Pending
                ) {
                    cnt++;
                }
            }
            return cnt;
        }
    }

    hideAllKebobMenus = () => {
        const menuItems = document.getElementsByClassName("grid-kebob-menu");
        if (menuItems && menuItems.length) {
            for (let i = 0; i < menuItems.length; i++) {
                menuItems[i].style.display = 'none';
            }
        }
    }

    isFromClass() {
        if (this.props.match.params.classId) {
            return true;
        }
        return false;
    }

    getColumns(isPrintedColumnVisible, enablePhoneRegistration) {
        return [
            {
                title: SchoolLocale.InvitationSchoolInvitationHistoryCode,
                dataIndex: "code",
                wordWrap: false,
            },
            {
                title: SchoolLocale.InvitationSchoolInvitationHistoryName,
                dataIndex: "name",
                wordWrap: false,
            },
            {
                title: SchoolLocale.InvitationSchoolInvitationHistoryStudent,
                dataIndex: "displayName",
                wordWrap: false,
                render: (text, data) => {
                    return `<span>${formatName(data.englishName, data.studentName)}</span>`
                }
            },
            {
                title: SchoolLocale.InvitationSchoolInvitationHistoryEmail,
                dataIndex: "email",
                wordWrap: false,
            },
            {
                title: SchoolLocale.InvitationHistoryGridHeadPhone,
                dataIndex: "phone",
                wordWrap: false,
                visible: isPhoneRegistrationEnable(enablePhoneRegistration)
            },
            {
                title: SchoolLocale.InvitationSchoolInvitationHistoryType,
                dataIndex: "typeText",
                align: "left",
                wordWrap: false,
            },
            {
                title: SchoolLocale.InvitationSchoolInvitationHistoryCampus,
                dataIndex: "campusName",
                wordWrap: false,
            },
            {
                title: SchoolLocale.InvitationSchoolInvitationHistoryClass,
                dataIndex: "schoolClassName",
                wordWrap: false,
            },
            {
                title: SchoolLocale.InvitationSchoolInvitationHistoryStatus,
                dataIndex: "invitationStatusText",
                align: "left",
                wordWrap: false,
            },
            {
                title: SchoolLocale.InvitationSchoolInvitationHistoryCreatedDate,
                dataIndex: "creationDate",
                wordWrap: false,
                render: (text, { creationDate }) => (creationDate ? DateHelper.formatDate2Local(creationDate) : ""),
            },
            {
                title: SchoolLocale.InvitationSchoolInvitationHistoryAcceptedDate,
                dataIndex: "acceptedDate",
                wordWrap: false,
                render: (text, { acceptedDate }) => (acceptedDate ? DateHelper.formatDate2Local(acceptedDate) : ""),
            },
            {
                title: SchoolLocale.InvitationSchoolInvitationHistoryExpirationDate,
                dataIndex: "expirationDate",
                wordWrap: false,
                render: (text, { expirationDate }) => expirationDate ? DateHelper.formatDate2Local(expirationDate) : "",
            },
            {
                title: SchoolLocale.InvitationSchoolInvitationHistoryExpirationDate,
                width: 40,
                minWidth: 40,
                maxWidth: 40,
                render: (text, record) => {
                    return `<i data-icon="grid-kebob" data-code="${record.code}" class="material-icons grid-kebob-icon">more_vert</i>
                            <div class="grid-kebob-menu" id="grid-kebob-${record.code}">
                                ${this.renderPrintIconOnGrid(isPrintedColumnVisible, record)}
                                ${this.renderEmailIconOnGrid(record)}
                                ${this.renderSMSIconOnGrid(enablePhoneRegistration, record)}
                                ${this.renderRenewIconOnGrid(record)}
                            </div>`
                }
            }
        ].map((column: any) => {
            column.sorter =
                column.dataIndex !== "code" && column.dataIndex !== "isPrinted" && column.dataIndex !== "isEmailed";
            return column;
        });
    }

    renderRenewIconOnGrid = (record: any) => {
        if (record.invitationStatus === 1 || record.invitationStatus === 2) {
            return "";
        }
        if (moment(record.expirationDate).isBefore(new Date()) || record.invitationStatus === 0) {
            const renewIcon = `<span role="img" aria-label="schedule" class="anticon anticon-schedule"><svg viewBox="64 64 896 896" class="invitations-wijmo-green-svg" focusable="false" data-icon="schedule" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M928 224H768v-56c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v56H548v-56c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v56H328v-56c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v56H96c-17.7 0-32 14.3-32 32v576c0 17.7 14.3 32 32 32h832c17.7 0 32-14.3 32-32V256c0-17.7-14.3-32-32-32zm-40 568H136V296h120v56c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-56h148v56c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-56h148v56c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-56h120v496zM416 496H232c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h184c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zm0 136H232c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h184c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zm308.2-177.4L620.6 598.3l-52.8-73.1c-3-4.2-7.8-6.6-12.9-6.6H500c-6.5 0-10.3 7.4-6.5 12.7l114.1 158.2a15.9 15.9 0 0025.8 0l165-228.7c3.8-5.3 0-12.7-6.5-12.7H737c-5-.1-9.8 2.4-12.8 6.5z"></path></svg></span>`;
            const renewText = fmtMsg(SchoolLocale.InvitationSchoolInvitationHistoryRenew);
            return `<div class="kebob-menu-item invitations-wijmo-actions" data-renew="true" data-invitation-code="${record.code}"> ${renewIcon} ${renewText}</div>`;
        }
        return "";
    }

    renderPrintIconOnGrid = (isPrintedColumnVisible, record: any) => {
        if (record.type != InvitationType.parent || !isPrintedColumnVisible) {
            return "";
        }
        const printerIcon = (cssClass: string) =>
            `<i aria-label="icon: printer" class="anticon anticon-printer"><svg viewBox="64 64 896 896" focusable="false" class="${cssClass}" data-icon="printer" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M820 436h-40c-4.4 0-8 3.6-8 8v40c0 4.4 3.6 8 8 8h40c4.4 0 8-3.6 8-8v-40c0-4.4-3.6-8-8-8zm32-104H732V120c0-4.4-3.6-8-8-8H300c-4.4 0-8 3.6-8 8v212H172c-44.2 0-80 35.8-80 80v328c0 17.7 14.3 32 32 32h168v132c0 4.4 3.6 8 8 8h424c4.4 0 8-3.6 8-8V772h168c17.7 0 32-14.3 32-32V412c0-44.2-35.8-80-80-80zM360 180h304v152H360V180zm304 664H360V568h304v276zm200-140H732V500H292v204H160V412c0-6.6 5.4-12 12-12h680c6.6 0 12 5.4 12 12v292z"></path></svg></i>`;
        const printText = record.isPrinted ?
            fmtMsg(SchoolLocale.InvitationSchoolInvitationHistoryPrintedStatus) :
            fmtMsg(SchoolLocale.InvitationSchoolInvitationHistoryPrint);
        if (record.invitationStatus !== InvitationListStates.Pending) {
            let disabledPrintHtml = (cssClass: string) => `<a class="invitations-wijmo-actions-disable">${printerIcon(cssClass)} <span class="dark-gray">${printText}</span></a>`;
            return `<div class="kebob-menu-item disabled-action">${disabledPrintHtml(record.isPrinted ? this.greenClass : this.redClass)}</div>`;
        }

        let printActionHtml = (cssClass) => `<div class="invitations-wijmo-actions kebob-menu-item"
                                                    title="${fmtMsg(SchoolLocale.InvitationSchoolInvitationHistoryPrintTitle)}"
                                                    data-id="${record.id}"
                                                    data-referenceId="${record.referenceId}"
                                                    data-type="${record.type}"
                                                    data-isPrint="${true}">${printerIcon(cssClass)} ${printText}</div>`;
        return printActionHtml(record.isPrinted ? this.greenClass : this.redClass);
    }

    renderEmailIconOnGrid = (record: any) => {
        const emailIcon = (cssClass: string) =>
            `<i aria-label="icon: mail" class="anticon anticon-mail"><svg viewBox="64 64 896 896" focusable="false" class="${cssClass}" data-icon="mail" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M928 160H96c-17.7 0-32 14.3-32 32v640c0 17.7 14.3 32 32 32h832c17.7 0 32-14.3 32-32V192c0-17.7-14.3-32-32-32zm-40 110.8V792H136V270.8l-27.6-21.5 39.3-50.5 42.8 33.3h643.1l42.8-33.3 39.3 50.5-27.7 21.5zM833.6 232L512 482 190.4 232l-42.8-33.3-39.3 50.5 27.6 21.5 341.6 265.6a55.99 55.99 0 0 0 68.7 0L888 270.8l27.6-21.5-39.3-50.5-42.7 33.2z"></path></svg></i>`;
        if (!record.email || record.invitationChannel !== InvitationChannel.Email) {
            return "";
        }
        let emailText = record.isEmailed ? fmtMsg(SchoolLocale.InvitationSchoolInvitationHistoryResendEmail) : fmtMsg(SchoolLocale.InvitationSchoolInvitationHistorySendEmail);
        if (record.invitationStatus !== InvitationListStates.Pending) {
            const disabledEmailHtml = (cssClass: string) => `<a class="invitations-wijmo-actions-disable">${emailIcon(cssClass)} <span class="dark-gray">${emailText}</span></a>`;
            return `<div class="kebob-menu-item disabled-action">${disabledEmailHtml(record.isEmailed ? this.greenClass : this.redClass)}</div>`;
        }
        let emailActionHtml = (cssClass: string) => `<div class="invitations-wijmo-actions kebob-menu-item"
                                                                title="${fmtMsg(SchoolLocale.InvitationSchoolInvitationHistoryEmailTitle)}"
                                                                data-id="${record.id}"
                                                                data-referenceId="${record.referenceId}"
                                                                data-type="${record.type}"
                                                                data-email="${record.email}"
                                                                data-isEmail="${true}">
                                                                ${emailIcon(cssClass)} ${emailText}</div>`;
        return emailActionHtml(record.isEmailed ? this.greenClass : this.redClass);
    }

    renderSMSIconOnGrid = (enablePhoneRegistration: boolean, record: any) => {

        if (!enablePhoneRegistration) {
            return "";
        }

        const messageIcon = (cssClass: string) =>
            `<i aria-label="icon: message" class="anticon anticon-message"><svg viewBox="64 64 896 896" focusable="false" class="${cssClass}" data-icon="message" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M464 512a48 48 0 1 0 96 0 48 48 0 1 0-96 0zm200 0a48 48 0 1 0 96 0 48 48 0 1 0-96 0zm-400 0a48 48 0 1 0 96 0 48 48 0 1 0-96 0zm661.2-173.6c-22.6-53.7-55-101.9-96.3-143.3a444.35 444.35 0 0 0-143.3-96.3C630.6 75.7 572.2 64 512 64h-2c-60.6.3-119.3 12.3-174.5 35.9a445.35 445.35 0 0 0-142 96.5c-40.9 41.3-73 89.3-95.2 142.8-23 55.4-34.6 114.3-34.3 174.9A449.4 449.4 0 0 0 112 714v152a46 46 0 0 0 46 46h152.1A449.4 449.4 0 0 0 510 960h2.1c59.9 0 118-11.6 172.7-34.3a444.48 444.48 0 0 0 142.8-95.2c41.3-40.9 73.8-88.7 96.5-142 23.6-55.2 35.6-113.9 35.9-174.5.3-60.9-11.5-120-34.8-175.6zm-151.1 438C704 845.8 611 884 512 884h-1.7c-60.3-.3-120.2-15.3-173.1-43.5l-8.4-4.5H188V695.2l-4.5-8.4C155.3 633.9 140.3 574 140 513.7c-.4-99.7 37.7-193.3 107.6-263.8 69.8-70.5 163.1-109.5 262.8-109.9h1.7c50 0 98.5 9.7 144.2 28.9 44.6 18.7 84.6 45.6 119 80 34.3 34.3 61.3 74.4 80 119 19.4 46.2 29.1 95.2 28.9 145.8-.6 99.6-39.7 192.9-110.1 262.7z"></path></svg></i>`;
        if (!record.phone || record.invitationChannel !== InvitationChannel.Phone) {
            return "";
        }
        let smsText = record.isSMSSent ? fmtMsg({ id: SchoolLocale.InvitationHistoryResendSMS }) : fmtMsg({ id: SchoolLocale.InvitationHistorySendSMS });
        if (record.invitationStatus !== InvitationListStates.Pending) {
            const disabledEmailHtml = (cssClass: string) => `<a class="invitations-wijmo-actions-disable">${messageIcon(cssClass)} <span class="dark-gray">${smsText}</span></a>`;
            return `<div class="kebob-menu-item disabled-action">${disabledEmailHtml(record.isSMSSent ? this.greenClass : this.redClass)}</div>`;
        }
        let emailActionHtml = (cssClass: string) => `<div class="invitations-wijmo-actions kebob-menu-item"
                                                                title="${fmtMsg(SchoolLocale.InvitationSchoolInvitationHistoryEmailTitle)}"
                                                                data-id="${record.id}"
                                                                data-referenceId="${record.referenceId}"
                                                                data-type="${record.type}"
                                                                data-phone="${record.phone}"
                                                                data-isPhone="${true}">
                                                                ${messageIcon(cssClass)} ${smsText}</div>`;
        return emailActionHtml(record.isSMSSent ? this.greenClass : this.redClass);
    }

    getInvitations() {
        let currentStoreId = this.props.match.params.schoolId;
        let defaultInvitationState = InvitationListStates.All;

        if (this.isFromClass()) {
            currentStoreId = this.props.match.params.classId;
            defaultInvitationState = InvitationListStates.Pending;
        }

        const invitationStatus =
            InvitationListStates.All === this.listStateStore.getState(currentStoreId, defaultInvitationState)
                ? null
                : this.listStateStore.getState(currentStoreId, defaultInvitationState);
        this.isPrintVisible = true;
        if (this.query.filters.filterType !== undefined) {
            if (
                this.query.filters.filterType == FilterType.Type &&
                this.query.filters.invitationCodeType !== undefined &&
                this.query.filters.invitationCodeType !== InvitationType.parent
            ) {
                this.isPrintVisible = false;
            }
        }
        this.resetStates();
        this.props.getInvitationCodeDetails({
            query: {
                resourceId: this.props.match.params.schoolId,
                ...this.query.filters,
                ...this.query.sorter,
                ...this.pagination.toRequest(),
                invitationStatus,
            },
        });
    }

    onColumnSorting(sorter: GLGridSorter) {
        const sorterFormat = (sorter: GLGridSorter) => {
            const mapper = {
                code: "Code",
                typeText: "InvitationType",
                invitationStatusText: "InvitationStatus",
                creationDate: "CreationDate",
                acceptedDate: "AcceptedDate",
                expirationDate: "ExpirationDate",
                schoolClassName: "AssociatedInfo.SchoolClass.Name",
                campusName: "AssociatedInfo.Campus.Name",
                email: "Email",
                phone: "Phone",
                studentName: "AssociatedInfo.Student.Name",
                name: "Name"
            };
            return sorter
                ? {
                    orderBy: sorter.columnSortName ? mapper[sorter.columnSortName] : sorter.columnSortName,
                    isDesc: !sorter.ascending,
                }
                : null;
        };
        this.query.sorter = sorterFormat(sorter);
        this.getInvitations();
    }
    handleTablePageChange(pagination, filters, sorter) {
        this.pagination.current = pagination.current;
        this.getInvitations();
    }
    handleSubmit(values) {
        this.query.filters = values;
        (this.tableProps.pagination as PaginationParams).current = 1;
        this.handleTablePageChange(new PaginationParams(1, 20), this.query.filters, this.query.sorter);
    }
    initFilterValue(form, field) {
        setTimeout(() => {
            switch (field) {
                case FilterType.Type:
                    form.setFieldsValue({ [FilterType.Type]: InvitationType.parent });
                    break;
                case FilterType.Class:
                    if (this.isFromClass()) {
                        form.setFieldsValue({ [FilterType.Class]: this.props.match.params.classId });
                    } else {
                        this.props.dataSource &&
                            this.props.dataSource.length > 0 &&
                            form.setFieldsValue({ [FilterType.Class]: this.props.dataSource[0].id });
                    }
                    break;
                case FilterType.CreatedDate:
                    form.setFieldsValue({ [FilterType.CreatedDate]: "7" });
                    break;
            }
        });
    }
    filterType(form) {
        const renderFormItem = FormHelper.renderFormItem;
        var field = form.getFieldValue("filterType");
        switch (field) {
            case FilterType.Type:
                return renderFormItem(
                    form,
                    SchoolLocale.InvitationSchoolInvitationHistoryValue,
                    FilterType.Type,
                    <Select {...alignPop()} className="filter-value">
                        <Select.Option value={InvitationType.parent}>
                            {fmtMsg(SchoolLocale.InvitationSchoolInvitationHistoryTypeParent)}
                        </Select.Option>
                        {this.isToShowTeacherType && (
                            <Select.Option value={InvitationType.classTeacher}>
                                {fmtMsg(SchoolLocale.InvitationSchoolInvitationHistoryTypeTeacher)}
                            </Select.Option>
                        )}
                        {this.isToShowCampusAdminType && (
                            <Select.Option value={InvitationType.campusAdmin}>
                                {fmtMsg(SchoolLocale.InvitationSchoolInvitationHistoryTypeCampus)}
                            </Select.Option>
                        )}
                        {this.isToShowSchoolAdminType && (
                            <Select.Option value={InvitationType.schoolAdmin}>
                                {fmtMsg(SchoolLocale.InvitationSchoolInvitationHistoryTypeSchool)}
                            </Select.Option>
                        )}
                    </Select>,
                    "",
                    true
                );
            case FilterType.Code:
                return renderFormItem(
                    form,
                    SchoolLocale.InvitationSchoolInvitationHistoryValue,
                    FilterType.Code,
                    <Input className="filter-value" />,
                    "",
                    true
                );
            case FilterType.Email:
            case FilterType.Phone:
                return renderFormItem(
                    form,
                    SchoolLocale.InvitationSchoolInvitationHistoryValue,
                    field,
                    <Input className="filter-value"></Input>,
                    "",
                    true,
                    field == FilterType.Email ?
                        [FormHelper.ruleForEmail(SchoolLocale.InvitationSchoolInvitationHistoryValue)] : null
                );
            case FilterType.Phone:
                return renderFormItem(
                    form,
                    SchoolLocale.InvitationSchoolInvitationHistoryValue,
                    FilterType.Phone,
                    <Input className="filter-value"></Input>,
                    "",
                    true,
                    [
                        {
                            validator: (rule, value, callback) => /^\+?[0-9]{0, 19}/g.test(value)
                                ? callback()
                                : callback("Phone format is not correct.")
                        }
                    ]
                );
            case FilterType.Class:
                return renderFormItem(
                    form,
                    SchoolLocale.InvitationSchoolInvitationHistoryValue,
                    FilterType.Class,
                    <Select {...alignPop()} className="filter-value">
                        {this.props.dataSource &&
                            this.props.dataSource.map((d) => (
                                <Select.Option key={d.id} value={d.id}>
                                    {d.name}
                                </Select.Option>
                            ))}
                    </Select>,
                    "",
                    true
                );
            case FilterType.CreatedDate:
                return renderFormItem(
                    form,
                    SchoolLocale.InvitationSchoolInvitationHistoryValue,
                    FilterType.CreatedDate,
                    <Select {...alignPop()} className="filter-value">
                        <Select.Option value="7">
                            {fmtMsg(SchoolLocale.InvitationSchoolInvitationHistoryCreatedDate7)}
                        </Select.Option>
                        <Select.Option value="30">
                            {fmtMsg(SchoolLocale.InvitationSchoolInvitationHistoryCreatedDate30)}
                        </Select.Option>
                        <Select.Option value="60">
                            {fmtMsg(SchoolLocale.InvitationSchoolInvitationHistoryCreatedDate60)}
                        </Select.Option>
                        <Select.Option value="90">
                            {fmtMsg(SchoolLocale.InvitationSchoolInvitationHistoryCreatedDate90)}
                        </Select.Option>
                    </Select>,
                    "",
                    true
                );
        }
        return null;
    }
    getListCnt({ all, pending, accepted, expired }) {
        switch (this.listStateStore.getState()) {
            case InvitationListStates.Pending:
                return pending;
            case InvitationListStates.Accepted:
                return accepted;
            case InvitationListStates.Expired:
                return expired;
            default:
                return all;
        }
    }
    printInvitations(items?) {
        let selectedItems = this.state.selectedItems;

        if (items !== undefined) {
            selectedItems = items;
        }

        this.invitationModalProps = {
            selectedItems: selectedItems,
            isEmail: false,
            isPhone: false,
            invitationType: this.getInvitationTypeFromSelections(selectedItems),
        };
        this.showHideInvitationModal(true, false);
    }
    emailInvitations(items?) {
        let selectedItems = this.state.selectedItems;

        if (items !== undefined) {
            selectedItems = items;
        }
        this.invitationModalProps = {
            selectedItems: selectedItems,
            isEmail: true,
            isPhone: false,
            invitationType: this.getInvitationTypeFromSelections(selectedItems),
        };
        this.showHideInvitationModal(true, false);
    }
    smsInvitations(items?) {
        let selectedItems = this.state.selectedItems;

        if (items !== undefined) {
            selectedItems = items;
        }
        this.invitationModalProps = {
            selectedItems: selectedItems,
            isEmail: false,
            isPhone: true,
            invitationType: this.getInvitationTypeFromSelections(selectedItems),
        };
        this.showHideInvitationModal(true, false);
    }
    getInvitationTypeFromSelections(items) {
        return items[0].type;
    }
    resetStates() {
        this.setState({
            selectedItems: [],
            invitationBanner: [],
        });
    }
    showHideInvitationModal(isVisible, isRefreshRequired) {
        this.setState({ showInvitationModal: isVisible });
        if (!isVisible && isRefreshRequired) {
            this.getInvitations();
        }
    }
    setViewAfterSelection(selectedData: InvitationSelectModel[], isFromRowHeader, isChecked) {
        const invitationBannerToSelect = [];

        if (selectedData.every((x) => x.type === selectedData[0].type)) {
            if (selectedData.length > 0) {
                let isParentType = selectedData[0].type === InvitationType.parent ? true : false;
                if (isParentType) {
                    if (selectedData.every((x) => x.email !== null) && selectedData.every((x) => x.phone !== null)) {
                        invitationBannerToSelect.push(InvitationBannerType.Email);
                        invitationBannerToSelect.push(InvitationBannerType.Phone);
                        invitationBannerToSelect.push(InvitationBannerType.Print);
                    } else if (selectedData.every((x) => x.email !== null)) {
                        invitationBannerToSelect.push(InvitationBannerType.Email);
                        invitationBannerToSelect.push(InvitationBannerType.Print);
                    } else if (selectedData.every((x) => x.phone !== null)) {
                        invitationBannerToSelect.push(InvitationBannerType.Phone);
                        invitationBannerToSelect.push(InvitationBannerType.Print);
                    } else {
                        invitationBannerToSelect.push(InvitationBannerType.Print);
                    }
                } else {
                    if (selectedData.every((x) => x.email !== null) && selectedData.every((x) => x.phone !== null)) {
                        invitationBannerToSelect.push(InvitationBannerType.Email);
                        invitationBannerToSelect.push(InvitationBannerType.Phone);
                    } else if (selectedData.every((x) => x.email !== null)) {
                        invitationBannerToSelect.push(InvitationBannerType.Email);
                    } else if (selectedData.every((x) => x.phone !== null)) {
                        invitationBannerToSelect.push(InvitationBannerType.Phone);
                    }
                }
            }
        } else {
            if (isFromRowHeader && !isChecked) {
                // Do nothing
            } else {
                MessageHelper.Message(
                    NotificationType.Warning,
                    fmtMsg({ id: SchoolLocale.InvitationSchoolInvitationHistorySameTypeErrorMsg })
                );
            }
        }

        this.setState({
            selectedItems: selectedData,
            invitationBanner: invitationBannerToSelect,
        });
    }

    getInvitationBanner = (enablePhoneRegistration: boolean) => {
        const { invitationBanner } = this.state;
        const toReturn = [];
        if (invitationBanner.some(s => s === InvitationBannerType.Print)) {
            toReturn.push(
                <a
                    href="javascript:void(0)"
                    onClick={() => this.printInvitations()}
                >
                    <span>
                        {fmtMsg(
                            SchoolLocale.InvitationSchoolInvitationHistoryPrintButton
                        )}
                    </span>
                </a>
            );
        }
        if (invitationBanner.some((s) => s === InvitationBannerType.Email)) {
            if (toReturn.length) {
                toReturn.push(
                    <>
                        &nbsp;
                        <span>
                            {fmtMsg(
                                SchoolLocale.InvitationSchoolInvitationHistoryPrintOrEmail
                            )}
                        </span>
                        &nbsp;
                    </>
                );
            }
            toReturn.push(
                <a
                    href="javascript:void(0)"
                    onClick={() => this.emailInvitations()}
                >
                    <span>
                        {fmtMsg(
                            SchoolLocale.InvitationSchoolInvitationHistoryEmailButton
                        )}
                    </span>
                </a>
            );
        }

        if (enablePhoneRegistration) {
            if (invitationBanner.some((s) => s === InvitationBannerType.Phone)) {
                if (toReturn.length) {
                    toReturn.push(
                        <>
                            &nbsp;
                            <span>
                                {fmtMsg(
                                    SchoolLocale.InvitationSchoolInvitationHistoryPrintOrEmail
                                )}
                            </span>
                            &nbsp;
                        </>
                    );
                }
                toReturn.push(
                    <a
                        href="javascript:void(0)"
                        onClick={() => this.smsInvitations()}
                    >
                        <span>{fmtMsg({ id: SchoolLocale.InvitationHistorySMSInvitations })}</span>
                    </a>
                );
            }
        }
        return toReturn;
    }

    getTypeText(type: InvitationType) {
        switch (type) {
            case InvitationType.subTeacher:
            case InvitationType.classTeacher:
                return fmtMsg(SchoolLocale.InvitationSchoolInvitationHistoryTypeTeacher);
            case InvitationType.parent:
                return fmtMsg(SchoolLocale.InvitationSchoolInvitationHistoryTypeParent);
            case InvitationType.campusAdmin:
                return fmtMsg(SchoolLocale.InvitationSchoolInvitationHistoryTypeCampus);
            case InvitationType.schoolAdmin:
                return fmtMsg(SchoolLocale.InvitationSchoolInvitationHistoryTypeSchool);
            default:
                return "";
        }
    }

    getStatusText(invitationStatus: InvitationListStates) {
        switch (invitationStatus) {
            case InvitationListStates.Pending:
                return fmtMsg(SchoolLocale.InvitationSchoolInvitationHistoryStatusPending);
            case InvitationListStates.Accepted:
            case InvitationListStates.ChildrenAdded:
                return fmtMsg(SchoolLocale.InvitationSchoolInvitationHistoryStatusAccepted);
            case InvitationListStates.Expired:
                return fmtMsg(SchoolLocale.InvitationSchoolInvitationHistoryStatusExpired);
            default:
                return "";
        }
    }

    formatData(data: any[]) {
        for (let index = 0; index < data.length; index++) {
            Object.assign(data[index], {
                typeText: this.getTypeText(data[index].type),
                invitationStatusText: this.getStatusText(data[index].invitationStatus),
                displayName: formatName(data[index].englishName, data[index].studentName, true)
            })
        }
        return data;
    }

    render() {
        this.pagination.total = this.getListCnt(this.props.cnts);
        this.tableProps.columns = this.getColumns(this.isPrintVisible, this.state.enablePhoneRegistration);
        this.tableProps.pagination = this.pagination;
        this.tableProps.dataSource = this.formatData(this.props.list);
        this.tableProps.loading = this.props.loading;
        const renderFormItem = FormHelper.renderFormItem;
        const form = this.props.form;
        const extendForm = { ...form, colon: true };
        const isSourceClass = this.isFromClass();
        const canDoPhoneRegistration = isPhoneRegistrationEnable(this.state.enablePhoneRegistration);
        return (
            <Container fullWidth className="invitations-list">
                <MainTitle title={SchoolLocale.InvitationPortalInvitations} />
                <div id={'grd-visitation-portal'}>
                    <GLForm layout="inline" form={form} onSubmit={onSubmit(this, this.handleSubmit.bind(this))}>
                        {renderFormItem(
                            extendForm,
                            SchoolLocale.InvitationSchoolInvitationHistoryField,
                            "filterType",
                            <Select
                                {...alignPop()}
                                className="filter-type"
                                onChange={(e) => {
                                    form.setFieldsValue({ filterType: e });
                                    this.initFilterValue(form, e);
                                }}
                            >
                                <Select.Option value={FilterType.Type}>
                                    {fmtMsg(SchoolLocale.InvitationSchoolInvitationHistoryType)}
                                </Select.Option>
                                <Select.Option value={FilterType.Code}>
                                    {fmtMsg(SchoolLocale.InvitationSchoolInvitationHistoryCode)}
                                </Select.Option>
                                <Select.Option value={FilterType.Email}>
                                    {fmtMsg(SchoolLocale.InvitationSchoolInvitationHistoryEmail)}
                                </Select.Option>
                                {canDoPhoneRegistration && <Select.Option value={FilterType.Phone}>
                                    {fmtMsg(SchoolLocale.InvitationHistoryGridFilterPhone)}
                                </Select.Option>}
                                <Select.Option value={FilterType.Class}>
                                    {fmtMsg(SchoolLocale.InvitationSchoolInvitationHistoryClassType)}
                                </Select.Option>
                                <Select.Option value={FilterType.CreatedDate}>
                                    {fmtMsg(SchoolLocale.InvitationSchoolInvitationHistoryCreatedDate)}
                                </Select.Option>
                            </Select>
                        )}
                        {this.filterType(extendForm)}
                        <Button className="filter-submit" type="primary" htmlType="submit">
                            {fmtMsg(SchoolLocale.InvitationSchoolInvitationHistorySearch)}
                        </Button>
                    </GLForm>
                    <div className="invitations-action">
                        {this.getInvitationBanner(this.state.enablePhoneRegistration)}
                    </div>
                    <div className="invitations-list-states">
                        <InvitationListStatesRadios
                            isFromClass={isSourceClass}
                            all={this.props.cnts.all}
                            pending={this.props.cnts.pending}
                            accepted={this.props.cnts.accepted}
                            expired={this.props.cnts.expired}
                            value={this.state.listState}
                            onChange={(state) => {
                                this.setState({ listState: state });
                                this.listStateStore.setState(state);
                                (this.tableProps.pagination as PaginationParams).current = 1;
                                this.handleTablePageChange(
                                    new PaginationParams(1, 20),
                                    this.query.filters,
                                    this.query.sorter
                                );
                            }}
                        />
                    </div>
                    <GLGrid
                        onColumnSorting={this.onColumnSorting.bind(this)}
                        onInitializeGrid={this.onInitializeGrid.bind(this)}
                        selectionMode={SelectionMode.ListBox}
                        {...this.tableProps}
                        headersVisibility={HeadersVisibility.All}
                        allowResizing={AllowResizing.Columns}
                        gridId={"grd-invitation-history"}
                    />
                    {this.state.showInvitationModal && (
                        <PortalInvitation
                            {...this.invitationModalProps}
                            visible={this.state.showInvitationModal}
                            onCancel={this.showHideInvitationModal.bind(this)}
                        />
                    )}
                </div>
            </Container>
        );
    }
}

const RadioGroup = Radio.Group;

interface InvitationListStatesRadiosProps {
    value?: any;
    pending?: number;
    accepted?: number;
    expired?: number;
    all?: number;
    onChange?: (e) => void;
    isFromClass: boolean;
}

interface InvitationListStatesRadiosStates { }

export class InvitationListStatesRadios extends Component<
    InvitationListStatesRadiosProps,
    InvitationListStatesRadiosStates
> {
    constructor(props) {
        super(props);
    }
    onChange(e) {
        this.props.onChange(parseInt(e.target.value));
    }
    renderState(text, cnt) {
        return (
            <span>
                <span>{fmtMsg(text)}</span>
                <span className="cnt">({cnt || 0})</span>
            </span>
        );
    }
    render() {
        const invitationState = this.props.isFromClass ? InvitationListStates.Pending : InvitationListStates.All;
        return (
            <div className="list-states">
                <RadioGroup value={this.props.value} defaultValue={invitationState} onChange={this.onChange.bind(this)}>
                    <Radio className="all" value={InvitationListStates.All}>
                        {this.renderState(SchoolLocale.InvitationSchoolInvitationHistoryStatusAll, this.props.all)}
                    </Radio>
                    <Radio className="pending" value={InvitationListStates.Pending}>
                        {this.renderState(
                            SchoolLocale.InvitationSchoolInvitationHistoryStatusPending,
                            this.props.pending
                        )}
                    </Radio>
                    <Radio className="accepted" value={InvitationListStates.Accepted}>
                        {this.renderState(
                            SchoolLocale.InvitationSchoolInvitationHistoryStatusAccepted,
                            this.props.accepted
                        )}
                    </Radio>
                    <Radio className="expired" value={InvitationListStates.Expired}>
                        {this.renderState(
                            SchoolLocale.InvitationSchoolInvitationHistoryStatusExpired,
                            this.props.expired
                        )}
                    </Radio>
                </RadioGroup>
            </div>
        );
    }
}
