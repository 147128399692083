import * as React from 'react'
import { Component } from 'react'
import { RouteComponentProps, Link } from "react-router-dom";
import { withRouter, InvitationType, GLGlobal, FormHelper, GLUtil, GLLocale, maskThrottle, unmaskThrottle, connect, StateType } from 'gl-commonui';
import { InvitationsComPage } from '@app/components';
import { SchoolLocale } from '@app/locales/localeid';
import { Checkbox, Select, Modal } from 'antd-min';
import { validTeacher, setMainTeacher } from '@app/states/school/invitation';
import { GSSchoolAction } from '@app/util/enum';
import { PathConfig } from '@app/config/pathconfig';
import { SchoolService } from '@app/service/schools';
import { get } from '../../../states/school/class';

interface TeachersPageProps {
    setMainTeacher?: (d) => void
    get: (d) => void
}
@withRouter
@connect(null, { setMainTeacher, get }, null)
export class TeachersPage extends Component<TeachersPageProps & RouteComponentProps<any>, any> {
    clickedBtns = []
    constructor(props, context) {
        super(props);
        this.state = {}

    }
    componentDidMount() {
        const { schoolId, classId: id } = this.props.match.params;
        if (schoolId && id) {
            this.props.get({ schoolId, id });
        }
    }

    renderOption(item, index) {
        return <Select.Option key={index} value={index} title={item}>{item}</Select.Option>
    }
    render() {
        const { match: { params: { regionId, schoolId, classId } }, setMainTeacher } = this.props;
        const fmtMsg = GLGlobal.intl.formatMessage;
        return (
            <InvitationsComPage
                refId={classId}
                typeId={InvitationType.classTeacher}
                typeTitle={fmtMsg({ id: SchoolLocale.InvitationTitleTeacher })}
                intl={getIntl()}
                clickedBtns={this.clickedBtns}
                emailConfirmBindForm={FormItemIsPrimary}
                addInvitationAction={GSSchoolAction.AddTeacher}
                deleteInvitationAction={GSSchoolAction.DeleteTeacher}
                sendEmailAction={GSSchoolAction.ResendEmail}
                removeHandler={removeHandler}
            />
        )
    }
}
function getColumns(setMainTeacher, regionId, schoolId) {
    const fmtMsg = GLGlobal.intl.formatMessage;
    return [{
        title: fmtMsg({ id: SchoolLocale.TeacherName }),
        dataIndex: "name",
        width: "30%",
        render: (text, record, index) => {
            const teacherName = record.pending ? `${(record.name || record.id)} (${GLGlobal.intl.formatMessage({ id: SchoolLocale.TeacherPending })})` : record.name;
            const pathSchoolTeacher = regionId && schoolId ? GLUtil.pathStringify(PathConfig.SchoolTeacher, { regionId, schoolId, teacherId: record.id }) : null;
            return pathSchoolTeacher && GLGlobal.isActionValid(GSSchoolAction.SchoolTeacherHome) && teacherName && !record.pending ?
                <Link className={'navigation-link'} to={pathSchoolTeacher}>
                    {teacherName}
                </Link>
                : teacherName
        }
    }, {
        title: fmtMsg({ id: SchoolLocale.InvitationEmail }),
        dataIndex: "email",
        width: "30%"
    }, {
        title: fmtMsg({ id: SchoolLocale.TeacherPrimary }),
        dataIndex: "primary",
        width: "30%",
        align: "center",
        render: (text, record, index) => {
            return (record.primary || record.disabled || record.pending) ? <Checkbox disabled checked={record.primary} /> :
                <Checkbox onChange={(e) => {
                    if (e.target.checked) {
                        setMainTeacher({ teacherId: record.id });
                    }
                }} />;
        }
    }]
}
function getIntl() {
    return {
        Invite: SchoolLocale.InvitationInviteTeacher,
        InviteTitle: SchoolLocale.InvitationInviteTitleTeacher,
    }
}
function ValidTeacher() {
    return {
        validator: (rule, value, callback) => validTeacher(value, callback)
    }
}
function FormItemIsPrimary(form, invitations) {
    const options = {
        formItemProps: { label: null },
        decoratorOptions: { valuePropName: 'checked' }
    }
    const disablePrimary = invitations && invitations.find(invitation => invitation.primary) != undefined
    return FormHelper.renderFormItem({ ...form, ...options }, SchoolLocale.TeacherPrimary, 'primary', <Checkbox className='checkbox' disabled={true}>{GLGlobal.intl.formatMessage({ id: SchoolLocale.TeacherPrimary })}</Checkbox>, !disablePrimary, false, [ValidTeacher()])
}
function removeHandler(record, remove, removePending, refreshData) {
    const fmtMsg = GLGlobal.intl.formatMessage;
    const { schoolId, classId } = GLUtil.pathParse(PathConfig.Teachers);
    record.pending && Modal.confirm({
        title: fmtMsg({ id: SchoolLocale.SchoolTeacherDeleteModalTitle }),
        content: fmtMsg({ id: SchoolLocale.InvitationRemoveInvitation }),
        okText: fmtMsg({ id: GLLocale.Ok }),
        cancelText: fmtMsg({ id: GLLocale.Cancel }),
        onOk: () => { removePending(record) }
    });
    if (!record.pending) {
        maskThrottle();
        const schoolService = new SchoolService();
        schoolService.getSchoolTeacher({ id: schoolId, teacherId: record.id, hasDisabled: true }).then(teachingClasses => {
            const currentClasses = teachingClasses.filter(tclass => tclass.schoolClassId == classId);
            //if teacher already removed, we refresh ui without showing message.
            currentClasses.length == 0 && refreshData({ userId: record.id, id: classId, type: InvitationType.classTeacher });
            currentClasses.length > 0 && teachingClasses.length == 1 && Modal.confirm({
                title: fmtMsg({ id: SchoolLocale.SchoolTeacherDeleteModalTitle }),
                content: fmtMsg({ id: SchoolLocale.ClassTeacherLastDeleteMessage }),
                okText: fmtMsg({ id: SchoolLocale.ClassTeacherDeleteModalYesText }),
                cancelText: fmtMsg({ id: SchoolLocale.ClassTeacherDeleteModalNoText }),
                maskClosable: true,
                className: "class-teacher-delete-modal",
                width: 540,
                onOk: () => { remove({ ...record, iscompleteDelete: true }) },    //Remove Completely
                onCancel: (e) => {                                              //Keep in Teacher List
                    if (typeof e == 'object' && e.triggerCancel) {
                        //do nothing, closed dialog by click [X]
                    }
                    else if (typeof e == 'function') {
                        remove(record);
                        e();
                    }
                }
            });
            currentClasses.length > 0 && teachingClasses.length > 1 && Modal.confirm({
                title: fmtMsg({ id: SchoolLocale.SchoolTeacherDeleteModalTitle }),
                content: fmtMsg({ id: SchoolLocale.InvitationRemoveUser }),
                okText: fmtMsg({ id: GLLocale.Ok }),
                cancelText: fmtMsg({ id: GLLocale.Cancel }),
                onOk: () => { remove(record) }
            });
            unmaskThrottle();
        }).catch((response) => {
            unmaskThrottle();
        });
    }
}