import { useEffect, useState, useRef } from "react";
import { StateType, GLForm, connect, GLFormComponentProps, InvitationType, FormHelper } from "gl-commonui";
import { getTemplate4Preview, resetTemplate4Preview } from "@app/states/invitation-template";
import { initTemplates } from '@app/states/school/invitation';
import { InvitationSelectModel, downloadInvitationsRequestModel, invitationCodeModel, emailInvitationsRequestModel } from "@app/service/school/invitation";
import React from "react";
import { Spin, Modal } from "gl-commonui/lib/antd-min";
import { SchoolLocale } from "@app/locales/localeid";
import { TemplatesSelect } from "@app/page/school/student/registration/generic-invitation";
import { Button } from "antd";
import { PreviewModal } from "@app/components/gl-preview";
import { sendInvitations } from "@app/states/school/invitation";
import { PreviewTemplate } from "@app/page/school/student/preview";
import { downloadManualInvitations } from "@app/states/school/invitation";
import { fmtMsg, InvitationChannel } from "@app/util";
import "./portal-invitation.less";


interface PortalInvitationProps extends GLFormComponentProps {
    templates: any[];
    initTemplates: (arg: {type: InvitationType}) => void;
    selectedItems: InvitationSelectModel[];
    isEmail: boolean;
    isPhone: boolean;
    visible: boolean;
    onCancel?: (value?: any, isRefresh?: any) => void;
    invitationType: InvitationType;
    loading: boolean;
    resetTemplate4Preview?: () => void;
    isProcessingEmail: boolean;
    sendInvitations: (value?: any) => void;
    downloadManualInvitations?: (values: any) => void;
}

let emailOrSMSModalProps = {};

export const PortalInvitation = connect(({ invitation: { templates, loading, isProcessingEmail }, }: StateType) => ({
    templates,
    loading,
    isProcessingEmail
}), {
    initTemplates,
    resetTemplate4Preview,
    sendInvitations,
    downloadManualInvitations
})((GLForm.create()((props: PortalInvitationProps) => {
    const [showPrintPreviewModal, setPrintPreviewModal] = useState(false);
    const [showEmailPreviewModal, setEmailPreviewModal] = useState(false);
    const [selectedTemplateId, setSelectedTemplateId] = useState("");
    const renderFormItem = FormHelper.renderFormItem;
    const isSendingEmail = useRef(false);
    const isPrinting = useRef(false);
    const form = props.form;

    useEffect(() => {
        props.initTemplates({type: props.invitationType});
    }, []);

    useEffect(() => {
        if (!props.isProcessingEmail && isSendingEmail.current) {
            props.onCancel(false, true);
        }
    }, [props.isProcessingEmail]);

    useEffect(() => {
        if (!props.loading && isPrinting.current) {
            props.onCancel(false, true);
        }
    }, [props.loading]);

    const onPrintPreview = (showPreviewModal) => {
        form.validateFields((err, values) => {
            if (!err) {
                if (!showPreviewModal) {
                    props.resetTemplate4Preview();
                }
                setSelectedTemplateId(values.templateId);
                setPrintPreviewModal(showPreviewModal);
            }
        });
    }

    const downloadInvitations = () => {
        form.validateFields((err, values) => {
            if (!err) {
                isPrinting.current = true;
                props.downloadManualInvitations(getDownloadInvitationRequestModel(values.templateId));
            }
        });
    }

    const getDownloadInvitationRequestModel = (templateId) => {

        let invitationsRequestModel: downloadInvitationsRequestModel = {
            invitationTemplateId: templateId,
            invitations: [],
            isReGenerateOrEmail: true
        };

        props.selectedItems.forEach(item => {
            let manualInvitation: invitationCodeModel = {
                codeId: item.id
            };

            invitationsRequestModel.invitations.push(manualInvitation);
        });

        return invitationsRequestModel;
    }

    const emailOrSMSInvitations = () => {
        form.validateFields((err, values) => {
            if (!err) {
                isSendingEmail.current = true;
                props.sendInvitations({ emailData: getRequiredData(values.templateId), withLoader: false });
            }
        });
    }

    const getRequiredData = (templateId) => {

        let dataToSend: emailInvitationsRequestModel = {
            invitationTemplateId: templateId,
            invitations: [],
            isReGenerateOrEmail: true,
            invitationChannel: props.isPhone ? InvitationChannel.Phone : InvitationChannel.Email
        }

        props.selectedItems.forEach(item => {
            let manualInvitation: invitationCodeModel = {
                codeId: item.id
            };

            dataToSend.invitations.push(manualInvitation);
        });
        return dataToSend;
    }

    const onEmailOrSMSPreview = (showPreviewModal, isSMS: boolean = false) => {
        form.validateFields((err, values) => {
            if (!err) {
                if (!showPreviewModal) {
                    props.resetTemplate4Preview();
                }
                else {
                    let firstItem = props.selectedItems[0];
                    emailOrSMSModalProps = {
                        type: props.invitationType,
                        refid: firstItem.referenceId,
                        tepid: values.templateId,
                        name: "",
                        email: isSMS ? null : firstItem.email,
                        phone: isSMS ? firstItem.phone : null
                    }
                }
                setSelectedTemplateId(values.templateId);
                setEmailPreviewModal(showPreviewModal);
            }
        });
    }

    const getTemplates = () => {
        return props.isPhone 
                ? props.templates.filter(t => t.invitationChannel === InvitationChannel.Phone)
                : props.templates.filter(t => t.invitationChannel === InvitationChannel.Email);
    }

    return <Modal
        title={props.isEmail 
                    ? fmtMsg({ id: SchoolLocale.InvitationPortalEmailInvitationHeading })
                    : props.isPhone 
                        ? fmtMsg({ id: SchoolLocale.InvitationHistoryModalSMSTitle })
                        : fmtMsg({ id: SchoolLocale.InvitationPortalPrintInvitationHeading })}
        destroyOnClose={true}
        footer={null}
        visible={props.visible}
        onCancel={(e) => props.onCancel(false, false)}
        className="portal-invitation"
    >
        <Spin spinning={props.loading || props.isProcessingEmail}>
            <GLForm layout="horizontal" className="" form={form}>
                {renderFormItem(form, SchoolLocale.InvitationTemplate, "templateId", <TemplatesSelect templates={getTemplates()} />, null, true)}
            </GLForm>
            <div className="actions">
                {props.isEmail && <>
                    <Button className="ant-btn-primary" onClick={(e) => emailOrSMSInvitations()}>
                        {fmtMsg({ id: SchoolLocale.InvitationPortalEmailButtonText })}
                    </Button>
                    <Button className="ant-btn" onClick={(e) => onEmailOrSMSPreview(true)}>
                        {fmtMsg({ id: SchoolLocale.InvitationPortalPreviewButtonText })}
                    </Button>
                </>}
                {props.isPhone && <>
                    <Button className="ant-btn-primary" onClick={(e) => emailOrSMSInvitations()}>
                        {fmtMsg({ id: SchoolLocale.InvitationHistoryModalSendSMS })}
                    </Button>
                    <Button className="ant-btn" onClick={(e) => onEmailOrSMSPreview(true, true)}>
                        {fmtMsg({ id: SchoolLocale.InvitationPortalPreviewButtonText })}
                    </Button>
                </>}
                {!props.isEmail && !props.isPhone && <>
                    <Button className="ant-btn-primary" onClick={(e) => downloadInvitations()}>
                        {fmtMsg({ id: SchoolLocale.InvitationPortalDownloadButtonText })}
                    </Button>
                    <Button className="ant-btn" onClick={(e) => onPrintPreview(true)}>
                        {fmtMsg({ id: SchoolLocale.InvitationPortalPreviewButtonText })}
                    </Button>
                </>
                }
                <Button className="ant-btn" onClick={(e) => props.onCancel(false, false)}>
                    {fmtMsg({ id: SchoolLocale.InvitationPortalCancelButtonText })}
                </Button>
            </div>
            {showPrintPreviewModal && <PreviewTemplate
                visible={showPrintPreviewModal}
                templateId4Preview={selectedTemplateId}
                onCancel={(e) => onPrintPreview(false)}
                isEmailTemplate={false}
            />}

            {showEmailPreviewModal && <PreviewModal modalparams={emailOrSMSModalProps} visible={showEmailPreviewModal}
                onCancel={() => onEmailOrSMSPreview(false)} />}
        </Spin>
    </Modal>

})));


PortalInvitation.defaultProps = {
    selectedItems: [],
    visible: false
};